import { ViewportScroller } from '@angular/common';
import {
  afterNextRender,
  EnvironmentProviders,
  inject,
  Injector,
  provideEnvironmentInitializer,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SessionStorageProvider } from '@isa/core/storage';

// const route: Route = {
//     component: AnyComponent,
//     data: {
//         scrollPositionRestoration: true
//     }
// }

const getDeepestActivatedRoute = (route: ActivatedRoute): ActivatedRoute => {
  while (route.firstChild) {
    route = route.firstChild;
  }
  return route;
};

export function provideScrollPositionRestoration(): EnvironmentProviders {
  return provideEnvironmentInitializer(() => {
    const router = inject(Router);
    const viewportScroller = inject(ViewportScroller);
    const sessionStorage = inject(SessionStorageProvider);

    router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationStart) {
        const url = router.url;
        const route = getDeepestActivatedRoute(router.routerState.root);

        if (route.snapshot.data?.['scrollPositionRestoration']) {
          sessionStorage.set(url, viewportScroller.getScrollPosition());
        }
      }
    });
  });
}

export async function restoreScrollPosition() {
  const injector = inject(Injector);
  const router = inject(Router);
  const viewportScroller = inject(ViewportScroller);
  const sessionStorage = inject(SessionStorageProvider);

  const url = router.url;
  const position = await sessionStorage.get(url);

  if (position) {
    afterNextRender(
      () => {
        viewportScroller.scrollToPosition(position as [number, number]);
      },
      { injector },
    );
  }
}
